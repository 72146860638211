import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  categories: [],
  products: [],
};

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    loadCategoriesRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    loadCategoriesSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.categories = action.payload?.result;
    },
    loadCategoriesFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    loadProductsRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
      state.hasResetted = false;
    },
    loadProductsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.products = action.payload?.result;
      // state.hasResetted = false;
      // state.products = [...state?.products, ...action?.payload?.result];
      // if (action.payload?.result?.length === 0) {
      //   state.hasMore = false;
      // } else {
      //   state.hasMore = true;
      // }
    },
    loadProductsFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    loadProductsReset(state, action: PayloadAction<any>) {
      state.loading = false;
      state.hasResetted = true;
      state.products = [];
    },
  },
});

export const {
  loadCategoriesSuccess,
  loadCategoriesFail,
  loadCategoriesRequested,
  loadProductsRequested,
  loadProductsSuccess,
  loadProductsFail,
  loadProductsReset,
} = productSlice.actions;

export default productSlice.reducer;
