import { put, call, takeEvery, select } from 'redux-saga/effects';
import { axiosInstance, apiURL } from 'config';
import {
  loadNotificationsRequested,
  loadNotificationsSuccess,
  loadNotificationsFail,
  loadNotificationStatusRequested,
  loadNotificationStatusSuccess,
  loadNotificationStatusFail,
} from './notificationSlice';
import { selectAuth } from 'store/auth/selector';

export function* loadNotificationsEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.get,
      `${apiURL}/get-notification-by-userid?userId=${action?.payload?.userId}`
    );
    if (data?.succeeded) {
      yield put(loadNotificationsSuccess(data));
    }
  } catch (error: any) {
    yield put(loadNotificationsFail(error.message));
  }
}

export function* createNotificationsEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.post,
      `${apiURL}/create-notification`,
      action.payload
    );
    if (data?.succeeded) {
      yield put(loadNotificationsSuccess(data));
    }
  } catch (error: any) {
    yield put(loadNotificationsFail(error.message));
  }
}

export function* loadNotificationStatusEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const auth = yield select(selectAuth);
  try {
    const { data } = yield call(
      axiosInstance.put,
      `${apiURL}/read-notification-status?id=${action?.payload?.id}`
    );
    if (data?.succeeded) {
      yield put(loadNotificationStatusSuccess(data));

      yield call(loadNotificationsEffect, {
        payload: { userId: auth?.auth?.user?.id },
        type: '',
      });
    }
  } catch (error: any) {
    yield put(loadNotificationStatusFail(error.message));
  }
}

export function* notificationsSaga(): Generator<any, void, any> {
  yield takeEvery(loadNotificationsRequested, loadNotificationsEffect);
  yield takeEvery(
    loadNotificationStatusRequested,
    loadNotificationStatusEffect
  );
}
