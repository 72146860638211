export const CalculateSelectionPrice: any = (
  combo: any,
  orderType: any,
  product: any
) => {
  let addonsTotal: number = 0;
  let productDiscount: number = 0;
  const addons = combo?.filter((item: any) => item?.selectOptionType === 2);
  addons?.length > 0 &&
    (addonsTotal = addons?.[0]?.description
      ?.filter((item: any) => item?.selected)
      ?.reduce((addonsTotal: any, item: any) => {
        return addonsTotal + item?.price;
      }, 0));

  let productPrice: number =
    orderType === 'delivery' ? product?.dPrice : product?.cPrice;

  productDiscount =
    product?.totalDiscount !== 0
      ? productPrice * (product?.totalDiscount / 100)
      : 0;

  return productPrice - productDiscount + addonsTotal;
};

export const CalculatePrice: any = (data: any, orderType: any) => {
  let addonsTotal: number = 0;
  const addons = data?.combo?.filter(
    (item: any) => item?.selectOptionType === 2
  );
  addons?.length > 0 &&
    (addonsTotal = addons?.[0]?.description
      ?.filter((item: any) => item?.selected)
      ?.reduce((addonsTotal: any, item: any) => {
        return addonsTotal + item?.price;
      }, 0));
  let productPrice: number =
    orderType === 'collection' ? data?.cPrice : data?.dPrice;

  return productPrice + addonsTotal;
};

export const CalculateDiscountedPrice: any = (data: any, orderType: any) => {
  let addonsTotal: number = 0;
  let productDiscount: number = 0;
  const addons = data?.combo?.filter(
    (item: any) => item?.selectOptionType === 2
  );
  addons?.length > 0 &&
    (addonsTotal = addons?.[0]?.description
      ?.filter((item: any) => item?.selected)
      ?.reduce((addonsTotal: any, item: any) => {
        return addonsTotal + item?.price;
      }, 0));
  let productPrice: number =
    orderType === 'collection' ? data?.cPrice : data?.dPrice;
  data?.totalDiscount > 0 &&
    (productDiscount = productPrice * (data?.totalDiscount / 100));
  return (productPrice - productDiscount + addonsTotal)?.toFixed(2);
};

export const CalculateDiscountedTotal = (data: any, orderType: any) => {
  let total: number = data?.reduce((total: any, product: any) => {
    let addonsTotal: number = 0;
    let productDiscount: number = 0;
    let price: number = 0;

    const addons = product?.combo?.filter(
      (item: any) => item?.selectOptionType === 2
    );

    addons?.length > 0 &&
      (addonsTotal = addons?.[0]?.description
        ?.filter((item: any) => item?.selected)
        ?.reduce((addonsTotal: any, item: any) => {
          return addonsTotal + item?.price;
        }, 0));
    let productPrice: number =
      orderType === 'collection' ? product?.cPrice : product?.dPrice;

    productDiscount =
      data?.totalDiscount !== 0
        ? productPrice * (product?.totalDiscount / 100)
        : 0;

    price = productPrice - productDiscount + addonsTotal;
    return total + price * product?.qty;
  }, 0);

  return total;
};

export const CalculateSubTotal = (data: any, orderType: any) => {
  let total: number = data?.reduce((total: any, product: any) => {
    let addonsTotal: number = 0;
    let price: number = 0;

    const addons = product?.combo?.filter(
      (item: any) => item?.selectOptionType === 2
    );

    addons?.length > 0 &&
      (addonsTotal = addons?.[0]?.description
        ?.filter((item: any) => item?.selected)
        ?.reduce((addonsTotal: any, item: any) => {
          return addonsTotal + item?.price;
        }, 0));
    let productPrice: number =
      orderType === 'collection' ? product?.cPrice : product?.dPrice;

    price = productPrice + addonsTotal;
    return total + price * product?.qty;
  }, 0);

  return total;
};

export const calculateDiscount = (data: any, orderType: any) => {
  let discount: number = 0;
  discount = data?.reduce((discount: any, item: any) => {
    let price: number =
      orderType === 'collection' ? item?.cPrice : item?.dPrice;
    return discount + price * item?.qty * (item?.totalDiscount / 100);
  }, 0);

  return discount;
};

export const calculateCouponDiscount = (coupon: any, total: number) => {
  let discount: number = 0;
  discount = coupon?.percentage ? (coupon?.percentage / 100) * total : 0;
  return discount;
};

export const hasComboItems = (value: any) => {
  const isComboItems =
    value?.combo?.filter(
      (item: any) => item?.selectOptionType !== 2 && item?.selectionCount === 0
    ).length > 0 ||
    value?.combo?.filter(
      (item: any) => item?.selectOptionType !== 2 && item?.selectionCount !== 0
    );

  return isComboItems;
};

export const hasAddonItems = (value: any) => {
  const addonItems = value?.combo?.filter(
    (item: any) => item?.selectOptionType === 2
  );
  const selectedAddons = addonItems?.flatMap((item: any) =>
    item?.description?.filter((desc: any) => desc.selected)
  );
  return selectedAddons?.length > 0;
};

export const defaultItemsDescription = (value: any) => {
  const defaultItems = value?.combo?.filter(
    (item: any) => item?.selectOptionType !== 2 && item?.selectionCount === 0
  );
  const description = defaultItems
    .flatMap((item: any) => item.description.map((desc: any) => desc.name))
    .join(', ');
  return description;
};

export const selectedItemsDescription = (value: any) => {
  const defaultItems = value?.combo?.filter(
    (item: any) => item?.selectOptionType !== 2 && item?.selectionCount === 0
  );
  const defaultDescription = defaultItems
    .flatMap((item: any) => item.description.map((desc: any) => desc.name))
    .filter(Boolean)
    .join(', ');

  const comboItems = value?.combo?.filter(
    (item: any) => item?.selectOptionType !== 2 && item?.selectionCount !== 0
  );

  const selectedDescription = comboItems
    .flatMap((item: any) => {
      return item?.description
        .filter((desc: any) => desc?.selected)
        .map((desc: any) => {
          const matchingVariance = item.variance?.find(
            (varianceItem: any) => varianceItem?.name === desc?.name
          );

          if (matchingVariance) {
            const selectedVariantDesc = matchingVariance.description.find(
              (variantDesc: any) => variantDesc?.selected
            );

            if (selectedVariantDesc) {
              return `${desc?.name}(${selectedVariantDesc?.name})`;
            }
          }
          return desc?.name;
        });
    })
    .filter(Boolean)
    .join(', ');

  const description = [defaultDescription, selectedDescription]
    .filter(Boolean)
    .join(', ');

  return description;
};

export const addonsItemsDescription = (value: any) => {
  const addonItems = value?.combo?.filter(
    (item: any) => item?.selectOptionType === 2
  );
  const addonsDescription = addonItems
    .flatMap((item: any) =>
      item.description
        .filter((desc: any) => desc.selected)
        .map((desc: any) => desc.name)
    )
    .filter(Boolean)
    .join(', ');

  return addonsDescription;
};

export const ItemDescription = (value: any) => {
  const defaultDescription = value?.combo
    ?.filter(
      (item: any) => item?.selectOptionType !== 2 && item?.selectionCount === 0
    )
    .flatMap((item: any) => item.description.map((desc: any) => desc.name))
    .filter(Boolean)
    .join(', ');

  const selectedDescription = value?.combo
    ?.filter(
      (item: any) => item?.selectOptionType !== 2 && item?.selectionCount !== 0
    )
    .flatMap((item: any) => {
      return item?.description
        .filter((desc: any) => desc?.selected)
        .map((desc: any) => {
          const matchingVariance = item.variance?.find(
            (varianceItem: any) => varianceItem?.name === desc?.name
          );

          if (matchingVariance) {
            const selectedVariantDesc = matchingVariance.description.find(
              (variantDesc: any) => variantDesc?.selected
            );

            if (selectedVariantDesc) {
              return `${desc?.name}(${selectedVariantDesc?.name})`;
            }
          }
          return desc?.name;
        });
    })
    .filter(Boolean)
    .join(', ');

  const addonsDescription = value?.combo
    ?.filter((item: any) => item?.selectOptionType === 2)
    .flatMap((item: any) =>
      item.description
        .filter((desc: any) => desc.selected)
        .map((desc: any) => desc.name)
    )
    .filter(Boolean)
    .join(', ');

  const description = [
    defaultDescription,
    selectedDescription,
    addonsDescription,
  ]
    .filter(Boolean)
    .join(', ');

  return description;
};
