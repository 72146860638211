import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'store';
import {
  forgotPasswordRequested,
  loginRequested,
  registerRequested,
  resetPasswordRequested,
} from 'store/auth/authSlice';

export function useLogin() {
  const dispatch = useDispatch();
  const { cart } = useSelector((state: RootState) => state.cart);
  const onLogin = useCallback(
    async (values: any, actions: any) => {
      actions.setSubmitting(true);
      await dispatch(
        loginRequested({
          password: values.password,
          username: values.username,
          userType: 1,
        })
      );
      setTimeout(() => {
        actions.setSubmitting(false);
      }, 500);
    },
    [dispatch, cart]
  );

  return { onLogin };
}

export function useRegister() {
  const dispatch = useDispatch();

  const onRegister = useCallback(
    async (values: any, actions: any) => {
      actions.setSubmitting(true);
      await dispatch(
        registerRequested({
          firstname: values.firstname,
          lastname: values.lastname,
          password: values.password,
          email: values.email,
          mobile: values.mobile,
          userType: 1,
        })
      );
    },
    [dispatch]
  );

  return { onRegister };
}

export function useForgotPassword() {
  const dispatch = useDispatch();

  const onForgotPassword = useCallback(
    async (values: any, actions: any) => {
      //   actions.setSubmitting(true);
      await dispatch(
        forgotPasswordRequested({
          email: values?.email,
        })
      );
    },
    [dispatch]
  );

  return { onForgotPassword };
}

export function useResetPassword() {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const extractedToken = searchParams.get('token');
  const onResetPassword = useCallback(
    async (values: any) => {
      await dispatch(
        resetPasswordRequested({
          password: values?.password,
          token: extractedToken || ' ',
        })
      );
    },
    [dispatch]
  );

  return { onResetPassword };
}
