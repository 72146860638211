import React from 'react';
import { Button, Input } from 'components/shared';
import { withFormik, FormikProps } from 'formik';
import { string, object } from 'yup';
import Chicken from 'assets/images/login/Chicken.png';
import GoogleLogin from '../GoogleLogin';

interface ISignUpProps {
  onSubmit?: any;
  setAuthOpenQuery?: any;
}

interface IFormValue {
  firstname: string;
  lastname: string;
  password: string;
  email: string;
  mobile: string;
}

function SignUp(props: FormikProps<IFormValue> & ISignUpProps) {
  return (
    <div className='p-6 max-w-4xl mx-auto rounded-lg shadow-md'>
      <div className='text-h3 font-poppinsSemiBold text-center mb-6 text-red-600'>
        Join Crazy Fried Chicken Today!
      </div>

      <div className='grid grid-cols-1 md:grid-cols-2 gap-8 items-center'>
        <div className='w-full flex justify-center'>
          <img
            src={Chicken}
            alt='login'
            className='max-w-full h-auto object-contain'
          />
        </div>

        <div className='space-y-4'>
          <form
            onSubmit={props.handleSubmit}
            className='w-full space-y-3'
          >
            <div className='space-y-3'>
              <Input
                name='email'
                label='Email'
                type='text'
                placeholder='Enter your Email'
                values={props.values}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                errors={props.errors}
                touches={props.touched}
              />
              <Input
                name='firstname'
                label='First Name'
                type='text'
                placeholder='Enter First Name'
                values={props.values}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                errors={props.errors}
                touches={props.touched}
              />
              <Input
                name='lastname'
                label='Last Name'
                type='text'
                placeholder='Enter Last Name'
                values={props.values}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                errors={props.errors}
                touches={props.touched}
              />
              <Input
                name='mobile'
                label='Mobile Number'
                type='tel'
                placeholder='Enter Mobile Number'
                values={props.values}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                errors={props.errors}
                touches={props.touched}
              />
              <Input
                name='password'
                label='Password'
                type='password'
                placeholder='Enter Password'
                values={props.values}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                errors={props.errors}
                touches={props.touched}
              />
              <div>
                <Button
                  title={props.isSubmitting ? 'Signing Up...' : 'Sign Up'}
                  type='submit'
                  variant='large'
                  disabled={props.isSubmitting}
                />
              </div>
            </div>

            <div className='flex justify-end items-center space-x-2 mt-4'>
              <div className='text-p3 text-end text-white'>
                Already have an account?
              </div>
              <div
                className='text-p3 text-end text-red-600 cursor-pointer font-poppinsBold'
                onClick={() => {
                  setTimeout(() => props.setAuthOpenQuery('login'), 300);
                }}
              >
                Login
              </div>
            </div>
          </form>

          <div className='mt-6'>
            <GoogleLogin />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withFormik<ISignUpProps, IFormValue>({
  validationSchema: object().shape({
    firstname: string().required('First Name is required'),
    lastname: string().required('Last Name is required'),
    password: string()
      .required('Password is required')
      .matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[^\w])/,
        'Password must contain at least one uppercase letter, one digit, and one special character.'
      ),
    email: string().required('Email is required').email('Invalid Email Format'),
    mobile: string()
      .required('Mobile Number is required')
      .matches(
        /^(?:\+44|0)\s?\d{10}$/,
        'Invalid UK phone number. Please enter a valid UK phone number with a +44 or 0 prefix and 10 digits.'
      ),
  }),
  mapPropsToValues: () => ({
    firstname: '',
    lastname: '',
    password: '',
    email: '',
    mobile: '',
  }),
  handleSubmit: (values, { props, ...actions }) => {
    props.onSubmit(values, actions);
  },
})(SignUp);
