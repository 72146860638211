import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as routes from 'constants/routes';
import { isAuthorized as useAuthorized } from 'store/auth/selector';

export const NavBar: React.FC = () => {
  const navigate = useNavigate();
  const isAuthorized = useSelector(useAuthorized);

  return (
    <div>
      <div className='flex items-center gap-4 mr-4 tracking-wider'>
        <div
          className='cursor-pointer'
          onClick={() => navigate(routes.HOME)}
        >
          HOME
        </div>
        <div
          className='cursor-pointer'
          onClick={() => navigate(routes.MENU)}
        >
          MENU
        </div>
        {isAuthorized ? (
          <div
            className='cursor-pointer'
            onClick={() => navigate(routes.ORDERS)}
          >
            ORDERS
          </div>
        ) : null}
      </div>
    </div>
  );
};
