import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { isAuthorized as useAuthorized } from 'store/auth/selector';
import * as routes from 'constants/routes';
import SignOutLight from 'assets/images/header/signout-light.svg';
import SignOutDark from 'assets/images/header/signout-dark.svg';
import ProfileLight from 'assets/images/header/profileLight.svg';
import ProfileDark from 'assets/images/header/profileDark.svg';
import SampleProfile from 'assets/images/header/sample-profile.svg';
import { ImageURL } from 'config';

interface IProfileMenu {
  theme: string;
  setOpen: any;
  setOpenUser: any;
  setOpenUserQuery: any;
  setOpenAuthQuery: any;
  profileImage: any;
}

export const ProfileMenu: React.FC<IProfileMenu> = ({
  theme,
  setOpen,
  setOpenUser,
  setOpenUserQuery,
  setOpenAuthQuery,
  profileImage,
}) => {
  const navigate = useNavigate();
  const isAuthorized = useSelector(useAuthorized);
  const { auth } = useSelector((state: RootState) => state.auth);
  const { userInfo } = useSelector((state: RootState) => state.user);

  const handleSignOut = () => {
    if (isAuthorized) {
      window.localStorage.clear();
      window.location.reload();
      localStorage.removeItem('persist:orderType');
    } else {
      setOpenUser(true);
      setOpenUserQuery('user');
      setOpenAuthQuery('login');
    }
  };
  const userName =
    userInfo?.firstName && userInfo?.lastName
      ? `${userInfo?.firstName} ${userInfo?.lastName}`
      : 'Guest';
  return (
    <div className='flex flex-col border border-pureWhite/20 backdrop-blur-xl text-textGray rounded-md bg-pureBlack/90 min-w-[14rem]'>
      {userInfo && Object.keys(userInfo)?.length > 0 && (
        <div className='flex items-center gap-3 rounded-t-md bg-cardGray/30 py-2 px-4 border-b-2 border-b-pureWhite/10'>
          <img
            src={profileImage}
            alt='profile'
            className='h-8 w-8 rounded-full'
          />
          <div className='text-textGray pr-8'>
            <div className='text-p2'>{userName}</div>
            <div className='text-p3'> {userInfo && userInfo?.email} </div>
          </div>
        </div>
      )}

      <div className='text-p2'>
        {isAuthorized && (
          <div
            className='px-4 py-2 hover:bg-pureWhite/10 cursor-pointer'
            onClick={() => [navigate(routes.PROFILE), setOpen(false)]}
          >
            <div className='flex gap-2 items-center '>
              <img
                src={theme === 'dark' ? ProfileDark : ProfileLight}
                alt='profile'
                className='w-4 h-4'
              />
              <div>Profile</div>
            </div>
          </div>
        )}
        <div
          className='px-4 py-2 hover:bg-pureWhite/20 cursor-pointer'
          onClick={handleSignOut}
        >
          <div className='flex gap-2 items-center'>
            <img
              src={theme === 'dark' ? SignOutDark : SignOutLight}
              alt='sign-out'
              className='w-4 h-4'
            />
            <div>{isAuthorized ? 'Sign Out' : 'SignIn'}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
