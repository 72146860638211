import { put, call, takeEvery, select } from 'redux-saga/effects';
import { axiosInstance, authURL } from 'config';
import {
  loadUserRequested,
  loadUserSuccess,
  loadUserFail,
  storeUserAddressRequested,
  storeUserAddressSuccess,
  storeUserAddressFail,
  updateUserAddressRequested,
  updateUserAddressSuccess,
  updateUserAddressFail,
  updateUserRequested,
  updateUserSuccess,
  updateUserFail,
  deleteUserAddressRequested,
  deleteUserAddressSuccess,
  deleteUserAddressFail,
  uploadProfileImageRequested,
  uploadProfileImageSuccess,
  uploadProfileImageFail,
  updateUserPasswordSuccess,
  updateUserPasswordFail,
  updateUserPasswordRequested,
} from './userSlice';
import { selectAuth } from 'store/auth/selector';
import toast from 'react-hot-toast';

export function* loadUserEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.get,
      `${authURL}/get-user-by-id?id=${action?.payload?.id}`,
      action.payload
    );
    yield put(loadUserSuccess(data));
  } catch (error: any) {
    yield put(loadUserFail(error.message));
  }
}

export function* updateUserEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const auth = yield select(selectAuth);
  try {
    const { data } = yield call(
      axiosInstance.put,
      `${authURL}/update-profile`,
      action.payload
    );
    if (data?.result) {
      yield put(updateUserSuccess(data));
      yield call(loadUserEffect, {
        payload: { id: auth?.auth?.user?.id },
        type: '',
      });
    } else {
      toast.error(data?.errors[0]);
    }
  } catch (error: any) {
    yield put(updateUserFail(error.message));
  }
}

export function* storeUserAddressEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const auth = yield select(selectAuth);
  try {
    const { data } = yield call(
      axiosInstance.post,
      `${authURL}/create-address-by-userid`,
      action.payload
    );
    if (data?.succeeded) {
      yield put(storeUserAddressSuccess(data));
      yield call(loadUserEffect, {
        payload: { id: auth?.auth?.user?.id },
        type: '',
      });
      toast.success('Address added successfully');
    } else {
      toast.error(data?.errors[0]);
    }
  } catch (error: any) {
    yield put(storeUserAddressFail(error.message));
  }
}

export function* updateUserAddressEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const auth = yield select(selectAuth);
  try {
    const { data } = yield call(
      axiosInstance.put,
      `${authURL}/update-address-by-id`,
      action.payload
    );
    if (data?.result) {
      yield put(updateUserAddressSuccess(data));
      yield call(loadUserEffect, {
        payload: { id: auth?.auth?.user?.id },
        type: '',
      });
    } else {
      toast.error(data?.errors[0]);
    }
  } catch (error: any) {
    yield put(updateUserAddressFail(error.message));
  }
}

export function* deleteUserAddressEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const auth = yield select(selectAuth);
  try {
    const { data } = yield call(
      axiosInstance.delete,
      `${authURL}/delete-address-by-id-userid?id=${action?.payload?.id}&userId=${action?.payload?.userId}`,
      action.payload
    );
    if (data?.result) {
      yield put(deleteUserAddressSuccess(data));
      toast.success(data?.result);
      yield call(loadUserEffect, {
        payload: { id: auth?.auth?.user?.id },
        type: '',
      });
    } else {
      toast.error(data?.errors[0]);
    }
  } catch (error: any) {
    yield put(deleteUserAddressFail(error.message));
  }
}

export function* uploadProfileImageEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const auth = yield select(selectAuth);
  try {
    const image = action?.payload;

    const formData = new FormData();
    formData.append('Image', image);

    const { data } = yield call(
      axiosInstance.post,
      `${authURL}/set-profile-image?Id=${auth?.auth?.user?.id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    if (data?.result) {
      yield put(uploadProfileImageSuccess(data));
      toast.success('Image Upload Successfully');
      yield call(loadUserEffect, {
        payload: { id: auth?.auth?.user?.id },
        type: '',
      });
    } else {
      toast.error(data?.errors[0]);
    }
  } catch (error: any) {
    yield put(uploadProfileImageFail(error.message));
  }
}

export function* updateUserPasswordEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const auth = yield select(selectAuth);
  try {
    const { data } = yield call(
      axiosInstance.post,
      `${authURL}/change-password`,
      action.payload
    );
    if (data?.result) {
      yield put(updateUserPasswordSuccess(data));
      toast.success(data?.result);
      yield call(loadUserEffect, {
        payload: { id: auth?.auth?.user?.id },
        type: '',
      });
    } else {
      toast.error(data?.errors[0]);
    }
  } catch (error: any) {
    yield put(updateUserPasswordFail(error.message));
  }
}

export function* userSaga(): Generator<any, void, any> {
  yield takeEvery(loadUserRequested, loadUserEffect);
  yield takeEvery(storeUserAddressRequested, storeUserAddressEffect);
  yield takeEvery(updateUserAddressRequested, updateUserAddressEffect);
  yield takeEvery(updateUserRequested, updateUserEffect);
  yield takeEvery(uploadProfileImageRequested, uploadProfileImageEffect);
  yield takeEvery(deleteUserAddressRequested, deleteUserAddressEffect);
  yield takeEvery(updateUserPasswordRequested, updateUserPasswordEffect);
}
