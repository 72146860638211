import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  loadCategoriesRequested,
  loadProductsRequested,
} from 'store/products/productSlice';

export function useLoadCategory() {
  const dispatch = useDispatch();

  const onLoadCategory = useCallback(async () => {
    await dispatch(loadCategoriesRequested({}));
  }, [dispatch]);

  useEffect(() => {
    onLoadCategory();
  }, [onLoadCategory]);

  return { onLoadCategory };
}

export function useLoadProduct() {
  const dispatch = useDispatch();

  const onLoadProduct = useCallback(
    async (values: any) => {
      if (values) {
        await dispatch(loadProductsRequested(values));
      }
    },
    [dispatch]
  );

  return { onLoadProduct };
}
