import React from 'react';
import { Button, Input } from 'components/shared';
import Chicken from 'assets/images/login/Chicken.png';
import { withFormik, FormikProps } from 'formik';
import { string, object, ref } from 'yup';

interface IResetPasswordProps {
  onSubmit?: any;
  setAuthOpenQuery?: any;
}

interface IFormValue {
  password: string;
  confirmPassword: string;
}

function ForgotPassword(props: FormikProps<IFormValue> & IResetPasswordProps) {
  return (
    <div className='h-fit w-full max-w-lg mx-auto px-4 pb-6 md:px-6 md:pb-10 lg:w-[55vw]'>
      <div
        className={`text-h3 font-poppinsMedium text-center mb-6 text-pureWhite text-white`}
      >
        Reset Password
      </div>
      <div className='flex flex-col md:flex-row items-center md:space-x-8'>
        <div className='w-full md:w-[50%] h-[50vw] md:h-[40vw] lg:h-[35vw] max-h-80 flex justify-center md:justify-end mb-6 md:mb-0'>
          <img
            src={Chicken}
            alt='login'
            className='w-full h-full object-cover'
          />
        </div>
        <div className='w-full md:w-[50%]'>
          <form
            onSubmit={props.handleSubmit}
            className='space-y-4'
          >
            <div className='space-y-4'>
              <Input
                name='password'
                type='password'
                placeholder='Enter password'
                values={props.values}
                onChange={props.handleChange}
                errors={props.errors}
                touches={props.touched}
              />
              <Input
                name='confirmPassword'
                type='password'
                placeholder='Enter confirm password'
                values={props.values}
                onChange={props.handleChange}
                errors={props.errors}
                touches={props.touched}
              />

              <div className='flex justify-center'>
                <Button
                  title={props.isSubmitting ? 'Logging In...' : 'Login'}
                  type='submit'
                  variant='large'
                  disabled={props.isSubmitting}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default withFormik<IResetPasswordProps, IFormValue>({
  validationSchema: object().shape({
    password: string()
      .required('Password is required')
      .matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[^\w])/,
        'Password must contain at least one uppercase letter, one digit, and one special character.'
      ),
    confirmPassword: string()
      .required('Password confirmation is required')
      .oneOf([ref('password'), ''], 'Passwords must match'),
  }),
  mapPropsToValues: () => ({
    password: '',
    confirmPassword: '',
  }),
  handleSubmit: (values, { props, ...actions }) => {
    props.onSubmit(values, actions);
  },
})(ForgotPassword);
