import React from 'react';
import { Helmet } from 'react-helmet-async';
import { globalMetadata } from './tag';

const MetaTags = ({ title, description, keywords, openGraph }: any) => {
  const metaTitle = title || globalMetadata.title;
  const metaDescription = description || globalMetadata.description;
  const metaKeywords = keywords || globalMetadata.keywords.join(', ');

  const ogData = openGraph || globalMetadata.openGraph;

  return (
    <Helmet prioritizeSeoTags>
      <title>{metaTitle}</title>
      <meta
        name='description'
        content={metaDescription}
      />
      <meta
        name='keywords'
        content={metaKeywords}
      />
      {/* Open Graph tags */}
      <meta
        property='og:title'
        content={ogData.title || metaTitle}
      />
      <meta
        property='og:description'
        content={ogData.description || metaDescription}
      />
      <meta
        property='og:url'
        content={ogData.url}
      />
      <meta
        property='og:site_name'
        content={ogData.siteName}
      />
      <meta
        property='og:locale'
        content={ogData.locale}
      />
      <meta
        property='og:type'
        content={ogData.type}
      />
      {/* Additional Meta Tags */}
      {globalMetadata.additionalMetaTags.map((tag: any, index: any) => (
        <meta
          key={index}
          name={tag.name}
          content={tag.content}
        />
      ))}
      {/* Canonical URL */}
      {globalMetadata.alternates?.canonical && (
        <link
          rel='canonical'
          href={globalMetadata.alternates.canonical}
        />
      )}
    </Helmet>
  );
};

export default MetaTags;
