import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  guest: null,
  address: null,
};

const guestSlice = createSlice({
  name: 'guest',
  initialState,
  reducers: {
    storeGuestRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    storeGuestSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.guest = action.payload;
    },
    storeGuestFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    storeGuestReset(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.guest = null;
    },

    storeAddressRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    storeAddressSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.address = action.payload;
    },
    storeAddressFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    storeAddressReset(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.address = null;
    },
  },
});

export const {
  storeGuestRequested,
  storeGuestSuccess,
  storeGuestFail,
  storeAddressRequested,
  storeAddressSuccess,
  storeAddressFail,
  storeGuestReset,
  storeAddressReset,
} = guestSlice.actions;

export default guestSlice.reducer;
