import { put, takeEvery } from 'redux-saga/effects';

import {
  storeGuestRequested,
  storeGuestSuccess,
  storeGuestFail,
  storeAddressRequested,
  storeAddressSuccess,
  storeAddressFail,
} from './guestSlice';

export function* storeGuestEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const data = action.payload;
    yield put(storeGuestSuccess(data));
  } catch (error: any) {
    yield put(storeGuestFail(error.message));
  }
}

export function* storeAddressEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const data = action.payload;
    yield put(storeAddressSuccess(data));
  } catch (error: any) {
    yield put(storeAddressFail(error.message));
  }
}

export function* guestSaga(): Generator<any, void, any> {
  yield takeEvery(storeGuestRequested, storeGuestEffect);
  yield takeEvery(storeAddressRequested, storeAddressEffect);
}
