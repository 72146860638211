import { put, call, takeEvery } from 'redux-saga/effects';
import { axiosInstance, authURL } from 'config';
import toast from 'react-hot-toast';
import {
  storeContactRequested,
  storeContactSuccess,
  storeContactFail,
} from './contactSlice';

function* storeContactEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.post,
      `${authURL}/create-contact`,
      action.payload
    );

    if (data?.succeeded) {
      yield put(storeContactSuccess(data));
      toast.success('Message sent Succeed');
    } else {
      if (data?.errors) {
        toast.error(data?.errors[0]);
      } else {
        toast.error('Message Sent Failed');
      }
    }
  } catch (error: any) {
    yield put(storeContactFail(error.message));
  }
}

export function* contactSaga(): Generator<any, void, any> {
  yield takeEvery(storeContactRequested, storeContactEffect);
}
