import { Outlet, useLocation } from 'react-router-dom';
import { Header, Footer } from 'components/base';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export const ClientLayout: React.FC = () => {
  const location = useLocation();
  const { theme } = useSelector((state: RootState) => state.theme);
  return (
    <div
      className={`${theme} font-poppins tracking-wide text-textGray relative`}
    >
      <div className='fixed top-0 left-0 w-full z-50'>
        <Header />
      </div>
      <div className={`${theme === 'dark' ? 'bg-black' : 'bg-white'}`}>
        <Outlet />
      </div>
      {location.pathname !== '/menu' && (
        <div className=''>
          <Footer />
        </div>
      )}
    </div>
  );
};
