import React from 'react';
import {  useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface InputProps {
  name?: any;
  type?: any;
  onChange?: any;
  values?: any;
  onBlur?: any;
  placeholder?: string;
  onKeyDown?: any;
  defaultValue?: any;
  touches?: any;
  errors?: any;
  disabled?: any;
  className?: string;
  label?: any;
  readOnly?: boolean;
}

export const Input: React.FC<InputProps> = ({
  name,
  type = 'text',
  onChange,
  values,
  onBlur,
  placeholder,
  onKeyDown,
  defaultValue,
  touches,
  errors,
  disabled,
  label,
  readOnly = false,
}) => {
  const { theme } = useSelector((state: RootState) => state.theme);
  const isInvalid = useMemo(() => {
    return touches?.[name] && errors?.[name];
  }, [touches, errors, name]);
  return (
    <div className='space-y-1'>
      {label && (
        <label
          htmlFor={name}
          className={`block text-sm font-medium ${
            theme === 'dark' ? 'text-gray-50' : 'text-gray-700'
          } mb-2`}
        >
          {label}
        </label>
      )}

      <input
        id={name}
        name={name}
        readOnly={readOnly}
        type={type}
        onChange={onChange}
        value={values?.[name]}
        onBlur={onBlur}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        defaultValue={defaultValue}
        className={`flex items-center px-2 pl-4 py-2 md:py-3 border ${
          theme === 'dark'
            ? 'bg-black/50 border-white/30 text-gray-50'
            : 'bg-white border-black/30 text-gray-950'
        } rounded-md text-xs w-full h-fit`}
        disabled={disabled}
      />
      {isInvalid ? (
        <div
          style={{ color: '#ef4444' }}
          className='font-semibold text-[10px] tracking-wider'
        >
          {errors[name]}
        </div>
      ) : null}
    </div>
  );
};
