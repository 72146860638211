import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  loadDashboardAdvertisementsRequested,
  loadDashboardHeroSettingsRequested,
  loadDashboardOffersRequested,
} from 'store/dashboard/dashboardSlice';
import { loadAcceptedReviewsFail } from 'store/review/reviewSlice';

export function useLoadDashboardHeroSettings() {
  const dispatch = useDispatch();
  const onLoadHeroSettings = useCallback(async () => {
    await dispatch(loadDashboardHeroSettingsRequested({}));
  }, [dispatch]);

  useEffect(() => {
    onLoadHeroSettings();
  }, [onLoadHeroSettings]);
  return { onLoadHeroSettings };
}

export function useLoadDashboardReviews() {
  const dispatch = useDispatch();

  const onLoadReviews = useCallback(async () => {
    await dispatch(loadAcceptedReviewsFail({}));
  }, [dispatch]);

  useEffect(() => {
    onLoadReviews();
  }, [onLoadReviews]);

  return { onLoadReviews };
}

export function useLoadDashboardOffers() {
  const dispatch = useDispatch();

  const onLoadOffers = useCallback(async () => {
    await dispatch(loadDashboardOffersRequested({}));
  }, [dispatch]);

  useEffect(() => {
    onLoadOffers();
  }, [onLoadOffers]);

  return { onLoadOffers };
}

export function useLoadDashboardAdvertisements() {
  const dispatch = useDispatch();

  const onLoadAdvertisements = useCallback(async () => {
    await dispatch(loadDashboardAdvertisementsRequested({}));
  }, [dispatch]);

  useEffect(() => {
    onLoadAdvertisements();
  }, [onLoadAdvertisements]);

  return { onLoadAdvertisements };
}
