import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RootState } from 'store';
import {
  loadNotificationsRequested,
  loadNotificationStatusRequested,
} from 'store/notifications/notificationSlice';

export function useLoadNotifications() {
  const dispatch = useDispatch();
  const { auth } = useSelector((state: RootState) => state.auth);
  const onLoadNotifications = useCallback(async () => {
    await dispatch(
      loadNotificationsRequested({
        userId: auth?.user?.id,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    onLoadNotifications();
  }, [onLoadNotifications]);
  return { onLoadNotifications };
}

export function useLoadNotificationStatus() {
  const dispatch = useDispatch();
  const onLoadNotificationStatus = useCallback(
    async (values: any) => {
      await dispatch(loadNotificationStatusRequested({ id: values }));
    },
    [dispatch]
  );

  return { onLoadNotificationStatus };
}
