import * as React from 'react';

interface BadgeProps {
  status: number | boolean | string | any;
}

interface StatusInfo {
  color: string;
  name: string;
}

export default function Badge({ status }: BadgeProps) {

  const checkStatus = (
    status: BadgeProps['status']
  ): StatusInfo | undefined => {
    if (status === 0) {
      return {
        color: 'bg-[#ffc300]',
        name: 'Pending',
      };
    } else if (status === 1) {
      return {
        color: 'bg-[#918b8b]',
        name: 'Accepted',
      };
    } else if (status === 2) {
      return {
        color: 'bg-[#e63946]',
        name: 'Rejected',
      };
    } else if (status === true) {
      return {
        color: 'bg-[#007f5f]',
        name: 'Active',
      };
    } else if (status === false) {
      return {
        color: 'bg-[#918b8b]',
        name: 'Archived',
      };
    } else if (status === 'available') {
      return {
        color: 'bg-[#007f5f]',
        name: 'In Stock',
      };
    } else if (status === 'out') {
      return {
        color: 'bg-[#e63946]',
        name: 'Out of Stock',
      };
    } else if (status?.type === 'order') {
      return {
        color: 'bg-[#007f5f]',
        name: status?.name,
      };
    } else if (status === 'Pending') {
      return {
        color: 'bg-[#ffc300]',
        name: 'Pending',
      };
    } else if (status === 'Delivered') {
      return {
        color: 'bg-[#007f5f]',
        name: 'Delivered',
      };
    } else if (status === 'Cancelled') {
      return {
        color: 'bg-[#e63946]',
        name: 'Cancelled',
      };
    } else if (status === 'Confirmed') {
      return {
        color: 'bg-[#fb5607]',
        name: 'Confirmed',
      };
    } else if (status === 'Accepted') {
      return {
        color: 'bg-[#918b8b]',
        name: 'Accepted',
      };
    } else if (status === 'Delivery') {
      return {
        color: 'bg-[#ffb703]',
        name: 'Delivery',
      };
    } else if (status === 'Collection') {
      return {
        color: 'bg-[#7350f0]',
        name: 'Collection',
      };
    }
  };

  const statusInfo = checkStatus(status);

  return (
    <React.Fragment>
      <span
        className={`uppercase text-[10px] tracking-widest font-poppinsSemiBold px-1 py-1 rounded-sm text-white ${statusInfo?.color}`}
      >
        {statusInfo?.name}
      </span>
    </React.Fragment>
  );
}
