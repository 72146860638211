import React from "react";
import Lottie from "react-lottie";
import NotFoundAnimation from "assets/animations/404.json";

interface ILoadingProps {
  animation?: string;
}

export const NotFound: React.FC = (props: ILoadingProps) => {
  return (
    <div>
      <div className='w-full h-screen flex justify-center items-center '>
        <Lottie
          options={{
            animationData: props?.animation ?? NotFoundAnimation,
          }}
        />
      </div>
    </div>
  );
};
export default NotFound;
