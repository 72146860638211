import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer, PURGE } from 'redux-persist';
import authReducer from 'store/auth/authSlice';
import themeReducer from 'store/theme/themeSlice';
import productReducer from 'store/products/productSlice';
import userReducer from 'store/user/userSlice';
import guestReducer from 'store/guest/guestSlice';
import cartReducer from 'store/cart/cartSlice';
import orderReducer from 'store/order/orderSlice';
import couponReducer from 'store/coupon/couponSlice';
import subscriptionReducer from 'store/subscription/subscriptionSlice';
import notificationReducer from 'store/notifications/notificationSlice';
import contactReducer from 'store/contact/contactSlice';
import dashboardReducer from 'store/dashboard/dashboardSlice';
import reviewSlice from 'store/review/reviewSlice';

//
import {
  persistConfig,
  cartConfig,
  orderConfig,
  themeConfig,
  guestConfig,
  couponConfig,
} from 'persist';

const appReducer = combineReducers({
  auth: persistReducer(persistConfig, authReducer),
  theme: persistReducer(themeConfig, themeReducer),
  cart: persistReducer(cartConfig, cartReducer),
  order: persistReducer(orderConfig, orderReducer),
  coupon: persistReducer(couponConfig, couponReducer),
  product: productReducer,
  user: userReducer,
  guest: persistReducer(guestConfig, guestReducer),
  subscription: subscriptionReducer,
  notification: notificationReducer,
  contact: contactReducer,
  dashboard: dashboardReducer,
  review: reviewSlice,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === PURGE) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
