import { put, select, takeEvery, call } from 'redux-saga/effects';
import { selectAuth } from 'store/auth/selector';

import {
  loadOrderTimeRequested,
  loadOrderTimeSuccess,
  loadOrderTimeFail,
  storeOrderRequested,
  storeOrderSuccess,
  storeOrderFail,
  loadOrdersRequested,
  loadOrdersSuccess,
  loadOrdersFail,
  loadOrderRequested,
  loadOrderFail,
  loadOrderSuccess,
  updateOrderTypeRequested,
  updateOrderTypeSuccess,
  updateOrderTypeFail,
} from './orderSlice';
import { apiURL, authURL, axiosInstance } from 'config';
import toast from 'react-hot-toast';
import { createNotificationsEffect } from 'store/notifications/sagas';
import { storeCartReset } from 'store/cart/cartSlice';
import { storeCouponReset } from 'store/coupon/couponSlice';
import { storeAddressReset, storeGuestReset } from 'store/guest/guestSlice';
import { ORDER_SUCCESS } from 'constants/routes';

export function* loadOrderTimeEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(axiosInstance.get, `${apiURL}/get-time-slots`);
    if (data?.succeeded) {
      yield put(loadOrderTimeSuccess(data));
    }
  } catch (error: any) {
    yield put(loadOrderTimeFail(error.message));
  }
}

export function* storeOrderEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const auth = yield select(selectAuth);
  try {
    const { data } = yield call(
      axiosInstance.post,
      `${apiURL}/create-order`,
      action.payload?.values
    );
    yield put(storeOrderSuccess(data));

    if (data?.succeeded) {
      yield call(createNotificationsEffect, {
        payload: {
          userId: auth?.auth?.user?.id,
          name: data.result,
          type: 'Order',
          description: 'Your order has been placed successfully',
          isActive: true,
        },
        type: '',
      });

      localStorage.removeItem('persist:cart');
      localStorage.removeItem('persist:coupon');
      localStorage.removeItem('persist:guest');
      localStorage.removeItem('persist:address');

      yield put(storeCartReset([]));
      yield put(storeCouponReset({}));
      yield put(storeGuestReset({}));
      yield put(storeAddressReset({}));
    }

    action.payload?.navigate(ORDER_SUCCESS?.replace(':id', data?.result?.id));

    toast.success('Your order placed successfully.');
  } catch (error: any) {
    yield put(storeOrderFail(error.message));
  }
}

export function* loadOrdersEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.get,
      `${apiURL}/get-order-by-userid?userId=${action?.payload?.userId}`
    );
    if (data?.succeeded) {
      yield put(loadOrdersSuccess(data));
    }
  } catch (error: any) {
    yield put(loadOrdersFail(error.message));
  }
}

export function* loadOrderEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.get,
      `${apiURL}/get-order-by-id?Id=${action.payload.id}`
    );
    yield put(loadOrderSuccess(data));
  } catch (error: any) {
    yield put(loadOrderFail(error.message));
  }
}

export function* updateOrderTypeEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const data = action.payload;
    yield put(updateOrderTypeSuccess(data));
  } catch (error: any) {
    yield put(updateOrderTypeFail(error.message));
  }
}

export function* orderSaga(): Generator<any, void, any> {
  yield takeEvery(loadOrderTimeRequested, loadOrderTimeEffect);
  yield takeEvery(storeOrderRequested, storeOrderEffect);
  yield takeEvery(loadOrdersRequested, loadOrdersEffect);
  yield takeEvery(loadOrderRequested, loadOrderEffect);
  yield takeEvery(updateOrderTypeRequested, updateOrderTypeEffect);
}
