import React from 'react';
import { Input, NumberInput } from 'components/shared';
import { FormikProps, withFormik } from 'formik';
import { object, string } from 'yup';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
interface IGuestDeliveryFormProps {
  onSubmit: any;
  orderType: string;
  address: any;
  guest: any;
}

interface IFormValue {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
}

function GuestCollectionForm(
  props: FormikProps<IFormValue> & IGuestDeliveryFormProps
) {
  const { theme } = useSelector((state: RootState) => state.theme);
  return (
    <form
      className='space-y-3 sm:space-y-4 p-4 rounded-md shadow-md w-full text-p2 font-poppinsMedium'
      onSubmit={props.handleSubmit}
    >
      <div
        className={`text-h5 text-center ${
          theme === 'dark' ? 'text-gray-50' : 'text-gray-950'
        }`}
      >
        Contact Details
      </div>
      <div className='flex flex-col sm:flex-row sm:gap-6 space-y-3 sm:space-y-0'>
        <div className='w-full sm:w-1/2'>
          <Input
            name='firstName'
            type='text'
            placeholder='Enter firstname'
            values={props.values}
            onChange={props.handleChange}
            errors={props.errors}
            touches={props.touched}
          />
        </div>
        <div className='w-full sm:w-1/2'>
          <Input
            name='lastName'
            type='text'
            placeholder='Enter lastname'
            values={props.values}
            onChange={props.handleChange}
            errors={props.errors}
            touches={props.touched}
          />
        </div>
      </div>
      <div className='flex flex-col sm:flex-row sm:gap-6 space-y-1'>
        <div className='w-full sm:w-1/2'>
          <Input
            name='email'
            type='email'
            placeholder='Enter email address'
            values={props.values}
            onChange={props.handleChange}
            errors={props.errors}
            touches={props.touched}
          />
        </div>
        <div className='w-full sm:w-1/2'>
          <NumberInput
            name='mobile'
            label=''
            placeholder='Enter your Mobile Number'
            values={props.values.mobile}
            onChange={(value) => {
              props.setFieldValue('mobile', value);
            }}
            onBlur={() => {
              props.setFieldTouched('mobile', true);
            }}
            errors={props.errors}
            touches={props.touched}
            disabled={false}
            defaultCountry='gb'
          />
        </div>
      </div>
      <button
        type='submit'
        className='mt-4 w-full bg-red-600 text-white py-2 rounded-md transition'
      >
        Submit
      </button>
    </form>
  );
}

export default withFormik<IGuestDeliveryFormProps, IFormValue>({
  validationSchema: object().shape({
    firstName: string().required('First name is required'),
    lastName: string().required('Last name is required'),
    email: string().required('Email is required'),
    mobile: string().required('Mobile Number is required'),
    // .matches(
    //   /^(?:\+44|0)\s?\d{10}$/,
    //   'Invalid UK phone number. Please enter a valid UK phone number with a +44 or 0 prefix and 10 digits.'
    // ),
  }),

  mapPropsToValues: ({ guest = {} }) => ({
    firstName: guest?.firstName || '',
    lastName: guest?.lastName || '',
    email: guest?.email || '',
    mobile: guest?.mobile || '',
  }),

  handleSubmit: (values, { props, ...actions }) => {
    props.onSubmit(values, actions);
  },
})(GuestCollectionForm);
