import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  auth: null,
  error: null,
  tokenType: 'Bearer',
  selectedOption: null,
  forgotPassword: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequested(
      state,
      action: PayloadAction<{
        username: string;
        password: string;
        userType: any;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    loginSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.auth = action.payload;
    },

    loginFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    registerRequested(
      state,
      action: PayloadAction<{
        firstname: string;
        lastname: string;
        password: string;
        email: string;
        mobile: string;
        userType: any;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    registerSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.auth = action.payload;
    },
    registerFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    googleLoginRequested(
      state,
      action: PayloadAction<{
        credential: string;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    googleLoginSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.auth = action.payload;
    },
    googleLoginFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    forgotPasswordRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    forgotPasswordSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.forgotPassword = action.payload;
    },
    forgotPasswordFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    resetPasswordRequested(
      state,
      action: PayloadAction<{
        password: string;
        token: string;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    resetPasswordSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.auth = action.payload;
    },
    resetPasswordFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loginRequested,
  loginSuccess,
  loginFailure,
  registerRequested,
  registerSuccess,
  registerFailure,
  googleLoginRequested,
  googleLoginSuccess,
  googleLoginFailure,
  forgotPasswordRequested,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resetPasswordRequested,
  resetPasswordSuccess,
  resetPasswordFail,
} = authSlice.actions;

export default authSlice.reducer;
