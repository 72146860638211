import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  theme: 'dark',
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    updateThemeRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    updateThemeSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.theme = action.payload;
    },
    updateThemeFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { updateThemeRequested, updateThemeSuccess, updateThemeFail } =
  themeSlice.actions;

export default themeSlice.reducer;
