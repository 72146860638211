import React from 'react';
import { Dialog } from 'primereact/dialog';
import CloseLight from 'assets/images/header/close-light.svg';
import CloseDark from 'assets/images/header/close-dark.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface IModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  setISOpen: any;
  closeIcon?: boolean;
}

export const Modal: React.FC<IModalProps> = ({
  isOpen,
  setISOpen,
  children,
  closeIcon = true,
}) => {
 const { theme } = useSelector((state: RootState) => state.theme);

  const renderHeader = () => {
    return (
      <>
        <div className='flex justify-end items-center h-10 px-4'>
          {closeIcon && (
            <img
              src={theme === 'dark' ? CloseDark : CloseLight}
              alt='close'
              className='w-4 cursor-pointer'
              onClick={() => setISOpen(false)}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <div>
      <Dialog
        visible={isOpen}
        onHide={() => setISOpen(false)}
        closable={false}
        header={renderHeader()}
        breakpoints={{ '960px': '75vw', '640px': '100vw' }}
        className={`${
          theme === 'dark'
            ? 'bg-black border-white/40'
            : 'bg-white border-black/50'
        } border-2  rounded-lg`}
      >
        {children}
      </Dialog>
    </div>
  );
};
