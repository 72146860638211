// src/store/rootSaga.ts
import { all } from 'redux-saga/effects';
import { productSaga } from './products/sagas';
import { authSaga } from './auth/sagas';
import { themeSaga } from './theme/sagas';
import { cartSaga } from './cart/sagas';
import { userSaga } from './user/sagas';
import { guestSaga } from './guest/sagas';
import { orderSaga } from './order/sagas';
import { subscriptionSaga } from './subscription/sagas';
import { notificationsSaga } from './notifications/sagas';
import { contactSaga } from './contact/sagas';
import { couponSaga } from './coupon/sagas';
import { dashboardSaga } from './dashboard/sagas';
import { reviewSaga } from './review/sagas';

export function* rootSaga() {
  yield all([
    productSaga(),
    authSaga(),
    themeSaga(),
    cartSaga(),
    userSaga(),
    guestSaga(),
    orderSaga(),
    couponSaga(),
    subscriptionSaga(),
    notificationsSaga(),
    contactSaga(),
    dashboardSaga(),
    reviewSaga(),
  ]);
}
