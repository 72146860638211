import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateThemeRequested } from "store/theme/themeSlice";

export function useUpdateTheme() {
  const dispatch = useDispatch();
  const onUpdateTheme = useCallback(
    async (values: any) => {
      await dispatch(updateThemeRequested(values));
    },
    [dispatch]
  );
  return { onUpdateTheme };
}
