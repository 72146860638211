import React from 'react';
import CloseDark from 'assets/images/header/close-dark.svg';
import CloseLight from 'assets/images/header/close-light.svg';
import { IoClose } from 'react-icons/io5';

import moment from 'moment';
import { useLoadNotificationStatus } from 'hooks/Notifications';

interface INotificationProps {
  theme: string;
  setOpen: any;
  data: any;
  length: any;
}

export const Notification: React.FC<INotificationProps> = ({
  theme,
  setOpen,
  data,
  length,
}) => {
  const { onLoadNotificationStatus } = useLoadNotificationStatus();
  return (
    <div className='absolute top-8 -right-20 bg-pureBlack w-[18rem] h-[20rem] border border-pureWhite/40 rounded-lg p-1 py-2  overflow-auto notification-scroll'>
      <div className='space-y-3'>
        {length === 0 ? (
          <div className='text-center text-gray-500'>No Notifications</div>
        ) : (
          data?.map((item: any, index: number) =>
            item?.isActive ? (
              <div
                className={` border-l-4 border-teal-400 text-textGray px-3 py-1 rounded-l-md bg-pureWhite/10`}
                key={index}
              >
                <h1 className='relative'>
                  <div
                    className='absolute top-0 right-0 cursor-pointer'
                    onClick={() => onLoadNotificationStatus(item?.id)}
                  >
                    <IoClose
                      size={22}
                      className='hover:text-red-500'
                    />
                  </div>
                  {item?.createAt
                    ? moment(item?.createAt).format('LLL')
                    : '----'}
                </h1>
                <div className='text-textGray/60'>
                  <p>{item?.name}</p>
                  <p className='font-bold'>{item?.description}</p>
                </div>
              </div>
            ) : null
          )
        )}
      </div>
    </div>
  );
};
