import React from 'react';
import { Button } from 'components/shared';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
interface IDeleteConfirmProps {
  onSubmit?: any;
  title: string;
}

export const DeleteConfirm: React.FC<IDeleteConfirmProps> = ({
  onSubmit,
  title,
}) => {
  const { theme } = useSelector((state: RootState) => state.theme);
  return (
    <div className='w-[90vw] sm:w-[70vw] lg:w-[40vw] px-4 pb-6 space-y-5 mx-auto'>
      <div
        className={`text-p3 tracking-wider text-center cursor-pointer  ${
          theme === 'dark' ? 'text-white' : 'text-black'
        }`}
      >
        {title}
      </div>

      <div className='flex justify-center'>
        <Button
          title='Delete'
          type='submit'
          variant='large'
          onClick={() => {
            onSubmit();
          }}
        />
      </div>
    </div>
  );
};
