import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  loading: false,
  error: null,
  email: null,
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    storeContactRequested(
      state,
      action: PayloadAction<{
        name: string;
        email: string;
        mobile: string;
        message: string;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    storeContactSuccess(
      state,
      action: PayloadAction<{ result: any; errors: any; succeeded: any }>
    ) {
      state.loading = false;
      state.error = null;
      state.storeContact = action?.payload;
    },
    storeContactFail(state, action: PayloadAction<{}>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { storeContactRequested, storeContactSuccess, storeContactFail } =
  contactSlice.actions;

export default contactSlice.reducer;
