import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  hero: null,
  offers: [],
  advertisements: [],
};

const dashboardSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    loadDashboardHeroSettingsRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    loadDashboardHeroSettingsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.hero = action.payload.result;
    },
    loadDashboardHeroSettingsFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    loadDashboardOffersRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    loadDashboardOffersSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.offers = action.payload?.result;
    },
    loadDashboardOffersFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    loadDashboardAdvertisementsRequested(state, action: PayloadAction<{}>) {
      state.loading = true;
      state.error = null;
    },
    loadDashboardAdvertisementsSuccess(
      state,
      action: PayloadAction<{ results: any }>
    ) {
      state.loading = false;
      state.error = null;
      state.advertisements = action.payload.results;
    },
    loadDashboardAdvertisementsFail(state, action: PayloadAction<{}>) {
      state.orderLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadDashboardAdvertisementsFail,
  loadDashboardAdvertisementsRequested,
  loadDashboardAdvertisementsSuccess,
  loadDashboardHeroSettingsFail,
  loadDashboardHeroSettingsRequested,
  loadDashboardHeroSettingsSuccess,
  loadDashboardOffersFail,
  loadDashboardOffersRequested,
  loadDashboardOffersSuccess,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
