import React from 'react';
import { Provider } from 'react-redux';
import { ClientRouter } from './router/Router';
import store from 'store/store';
import { PrimeReactProvider } from 'primereact/api';
import { Toaster } from 'react-hot-toast';
import { SiTicktick } from 'react-icons/si';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import MetaTags from 'utils/meta/MetaTag';

function App() {
  return (
    <Provider store={store}>
      <HelmetProvider>
        <MetaTags
          title='Crazy Fried Chicken'
          description='Savor the best crispy fried chicken! Crazy Fried Chicken offers mouthwatering flavors, golden-crispy perfection, and delicious sides. Perfect for dine-in, takeout, or delivery. Order now!'
          keywords='crispy fried chicken, flavors, dine-in, collection, delivery'
        />
        <PrimeReactProvider>
          <ClientRouter />
          <Toaster
            toastOptions={{
              success: {
                className:
                  'text-green-900 text-xs font-semibold tracking-widest bg-green-100 w-auto',
                icon: <SiTicktick className=' text-green-700  h-4 w-4' />,
                position: 'bottom-right',
                duration: 4000,
              },
              error: {
                className:
                  'text-red-900 text-xs font-semibold tracking-widest bg-red-100 w-auto',
                icon: <RiErrorWarningLine className=' text-red-800  h-5 w-5' />,
                position: 'bottom-right',
                duration: 4000,
              },
            }}
          />
        </PrimeReactProvider>
      </HelmetProvider>
    </Provider>
  );
}

export default App;
