import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  notifications: [],
};

const notificationSlice = createSlice({
  name: 'Notifications',
  initialState,
  reducers: {
    loadNotificationsRequested(state, action: PayloadAction<{ userId: any }>) {
      state.loading = true;
      state.error = null;
    },
    loadNotificationsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.notifications = action.payload?.result;
    },
    loadNotificationsFail(state, action: PayloadAction<{}>) {
      state.loading = false;
      state.error = action.payload;
    },
    loadNotificationStatusRequested(state, action: PayloadAction<{ id: any }>) {
      state.loading = true;
      state.error = null;
    },
    loadNotificationStatusSuccess(state, action: PayloadAction<{}>) {
      state.loading = false;
      state.error = null;
      state.notificationstatus = action.payload;
    },
    loadNotificationStatusFail(state, action: PayloadAction<{}>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadNotificationsRequested,
  loadNotificationsSuccess,
  loadNotificationsFail,
  loadNotificationStatusRequested,
  loadNotificationStatusSuccess,
  loadNotificationStatusFail,
} = notificationSlice.actions;

export default notificationSlice.reducer;
