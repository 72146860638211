import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RootState } from 'store';
import {
  storeCouponRequested,
  storeCouponReset,
} from 'store/coupon/couponSlice';

export function useCheckCoupon() {
  const dispatch = useDispatch();
  const { auth } = useSelector((state: RootState) => state.auth);
  const onCheckCoupon = useCallback(
    async (values: any, actions: any) => {
      await dispatch(
        storeCouponRequested({
          coupon: values.coupon,
          priceLimit: values.priceLimit,
          userId: auth?.user?.id,
        })
      );
    },
    [dispatch]
  );

  return { onCheckCoupon };
}

export function useDeleteCoupon() {
  const dispatch = useDispatch();

  const onDeleteCoupon = useCallback(async () => {
    await dispatch(storeCouponReset({}));
  }, [dispatch]);

  return { onDeleteCoupon };
}
