import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormikProps, withFormik } from 'formik';
import { object, string } from 'yup';
import { PostcodeLookup } from '@ideal-postcodes/postcode-lookup';
import { Input, NumberInput } from 'components/shared';
import { RootState } from 'store';
import { postalCodeKey } from 'config';
interface IGuestDeliveryFormProps {
  onSubmit: any;
  orderType: string;
  address: any;
  guest: any;
}

interface IFormValue {
  firstName: string;
  lastName: string;
  postalcode: string;
  streetAndHouseNo: string;
  locality: string;
  email: string;
  mobile: string;
}

function GuestDeliveryForm(
  props: FormikProps<IFormValue> & IGuestDeliveryFormProps
) {
  useEffect(() => {
    PostcodeLookup.setup({
      apiKey: postalCodeKey,
      context: '#context',
      outputFields: {
        line_1: '#streetAndHouseNo',
        line_2: '#line_2',
        line_3: '#line_3',
        post_town: '#locality',
        postcode: '#postalcode',
      },
    });
  }, []);

  const { theme } = useSelector((state: RootState) => state.theme);

  return (
    <>
      <link
        href='https://img.ideal-postcodes.co.uk/demo.css'
        rel='stylesheet'
      />

      <form
        className='space-y-3 sm:space-y-4 p-4 rounded-md shadow-md w-full text-p2 font-poppinsMedium'
        onSubmit={props.handleSubmit}
      >
        <div
          className={`text-h5 text-center ${
            theme === 'dark' ? 'text-gray-50' : 'text-gray-950'
          }`}
        >
          Contact Details
        </div>
        <div className='flex flex-col sm:flex-row sm:gap-6 space-y-3 sm:space-y-0'>
          <div className='w-full sm:w-1/2'>
            <Input
              name='firstName'
              type='text'
              placeholder='Enter firstname'
              values={props.values}
              onChange={props.handleChange}
              errors={props.errors}
              touches={props.touched}
            />
          </div>
          <div className='w-full sm:w-1/2'>
            <Input
              name='lastName'
              type='text'
              placeholder='Enter lastname'
              values={props.values}
              onChange={props.handleChange}
              errors={props.errors}
              touches={props.touched}
            />
          </div>
        </div>

        <div
          style={{ display: 'block', visibility: 'visible' }}
          id='context'
        ></div>
        <div>
          <Input
            name='postalcode'
            type='text'
            placeholder='Enter postal code'
            values={props.values}
            onChange={props.handleChange}
            errors={props.errors}
            touches={props.touched}
          />
        </div>
        <div className='flex flex-col sm:flex-row sm:gap-6 space-y-3 sm:space-y-0'>
          <div className='w-full sm:w-1/2'>
            <Input
              name='streetAndHouseNo'
              type='text'
              placeholder='Enter street name'
              values={props.values}
              onChange={props.handleChange}
              errors={props.errors}
              touches={props.touched}
            />
          </div>
          <div className='w-full sm:w-1/2'>
            <Input
              name='locality'
              type='text'
              placeholder='Enter city'
              values={props.values}
              onChange={props.handleChange}
              errors={props.errors}
              touches={props.touched}
            />
          </div>
        </div>

        <div className='flex flex-col sm:flex-row sm:gap-6 space-y-1'>
          <div className='w-full sm:w-1/2'>
            <Input
              name='email'
              type='email'
              placeholder='Enter email address'
              values={props.values}
              onChange={props.handleChange}
              errors={props.errors}
              touches={props.touched}
            />
          </div>
          <div className='w-full sm:w-1/2'>
            <NumberInput
              name='mobile'
              label=''
              placeholder='Enter your Mobile Number'
              values={props.values.mobile}
              onChange={(value) => {
                props.setFieldValue('mobile', value);
              }}
              onBlur={() => {
                props.setFieldTouched('mobile', true);
              }}
              errors={props.errors}
              touches={props.touched}
              disabled={false}
              defaultCountry='gb'
            />
          </div>
        </div>

        <button
          type='submit'
          className='mt-4 w-full bg-red-600 text-white py-2 rounded-md transition border-none'
        >
          Submit
        </button>
      </form>
    </>
  );
}

export default withFormik<IGuestDeliveryFormProps, IFormValue>({
  validationSchema: object().shape({
    firstName: string().required('First name is required'),
    lastName: string().required('Last name is required'),
    postalcode: string()
      .required('Postal code is required')
      .transform((value) => (value ? value.toLowerCase() : value))
      .test(
        'starts-with-hp1-hp2-hp3',
        'Postal code must start with HP1, HP2, or HP3',
        (value) => /^hp[1-3]/.test(value)
      ),
    streetAndHouseNo: string().required('Street and HouseNo is required'),
    locality: string().required('Locality is required'),
    email: string().required('Email is required'),
    mobile: string().required('Mobile Number is required'),
  }),

  mapPropsToValues: ({ address = {}, guest = {} }) => ({
    firstName: guest?.firstName || '',
    lastName: guest?.lastName || '',
    postalcode: address?.postalcode || '',
    streetAndHouseNo: address?.streetAndHouseNo || '',
    locality: address?.locality || '',
    email: guest?.email || '',
    mobile: guest?.mobile || '',
  }),

  handleSubmit: (values, { props, ...actions }) => {
    props.onSubmit(values, actions);
  },
})(GuestDeliveryForm);
