//client routes
export const HOME = '/';
export const MENU = '/menu';
export const RESTAURANT = '/restaurant';
export const PROFILE = '/profile';
export const TERMS = '/terms-and-conditions';
export const ORDERS = '/orders';
export const COOKIESPOLICY = '/cookies-policy';
export const ORDER_DETAIL = '/order/:id';
export const ORDER_SUMMARY = '/order-summary';
export const ORDER_SUCCESS = '/order-success/:id';
export const CHECKOUT = '/order-checkout';
export const CONTACTUS = '/contact-us';
export const REVIEWS_FORM = '/feedback';
