import React from 'react';
import Login from 'templates/Auth/Login';
import SignUp from 'templates/Auth/SignUp';
import {
  useForgotPassword,
  useLogin,
  useRegister,
  useResetPassword,
  useStoreGuest,
} from 'hooks';
import ForgotPassword from 'templates/Auth/ForgotPassword';
import ResetPassword from 'templates/Auth/ResetPassword';
import { useNavigate } from 'react-router-dom';
import * as routes from 'constants/routes';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import GuestDeliveryForm from 'templates/OrderSummary/GuestDeliveryForm';
import GuestCollectionForm from 'templates/OrderSummary/GuestCollectionForm';

interface IUserProps {
  orderType?: any;
  openUserQuery?: any;
  setOpenUserQuery?: any;
  openAuthQuery?: any;
  setOpenAuthQuery?: any;
  address?: any;
  guest?: any;
}

const User: React.FC<IUserProps> = ({
  orderType,
  openUserQuery,
  setOpenUserQuery,
  openAuthQuery,
  setOpenAuthQuery,
  address,
  guest,
}) => {
  const navigate = useNavigate();

  const { onLogin } = useLogin();
  const { onRegister } = useRegister();
  const { onStoreGuest } = useStoreGuest();
  const { onForgotPassword } = useForgotPassword();
  const { onResetPassword } = useResetPassword();
  const { theme } = useSelector((state: RootState) => state.theme);

  return (
    <div className='text-white'>
      {openUserQuery === 'user' && openAuthQuery === 'login' && (
        <div>
          <Login
            onSubmit={onLogin}
            setAuthOpenQuery={setOpenAuthQuery}
          />
          <div
            className={`flex font-poppinsMedium text-p2 tracking-wider justify-center py-6 cursor-pointer hover:underline transition duration-200 ${
              theme === 'dark' ? 'text-gray-50' : 'text-gray-950'
            }`}
            onClick={() => [setOpenUserQuery('guest'), setOpenAuthQuery('')]}
          >
            Continue as Guest
          </div>
        </div>
      )}

      {openUserQuery === 'user' && openAuthQuery === 'signup' && (
        <div>
          <SignUp
            onSubmit={onRegister}
            setAuthOpenQuery={setOpenAuthQuery}
          />
          <div
            className={`flex font-poppinsMedium text-p2 tracking-wider justify-center py-6 cursor-pointer hover:underline transition duration-200 ${
              theme === 'dark' ? 'text-gray-50' : 'text-gray-950'
            }`}
            onClick={() => setOpenUserQuery('guest')}
          >
            Continue as Guest
          </div>
        </div>
      )}
      {openUserQuery === 'user' && openAuthQuery === 'forgot' && (
        <div>
          <ForgotPassword
            onSubmit={onForgotPassword}
            setAuthOpenQuery={setOpenAuthQuery}
          />
        </div>
      )}
      {openUserQuery === 'user' && openAuthQuery === 'reset' && (
        <ResetPassword
          onSubmit={async (auth: any) => {
            await onResetPassword(auth);
            navigate(routes.HOME);
          }}
          setAuthOpenQuery={setOpenAuthQuery}
        />
      )}
      {openUserQuery === 'guest' && (
        <div className='w-full md:w-[70vw] lg:w-[50vw] mx-auto'>
          {orderType === 'delivery' ? (
            <GuestDeliveryForm
              orderType={orderType}
              address={address}
              guest={guest}
              onSubmit={onStoreGuest}
            />
          ) : (
            <GuestCollectionForm
              orderType={orderType}
              address={address}
              guest={guest}
              onSubmit={onStoreGuest}
            />
          )}

          <div
            className={`flex font-poppinsMedium text-p2 tracking-wider justify-center py-6 cursor-pointer hover:underline transition duration-200 ${theme === 'dark' ? 'text-gray-50' : 'text-gray-950'
            }`}
            onClick={() => [
              setOpenUserQuery('user'),
              setOpenAuthQuery('login'),
            ]}
          >
            Already have Account / SignUp
          </div>
        </div>
      )}
    </div>
  );
};

export default User;
