import { put, call, takeEvery } from 'redux-saga/effects';
import { axiosInstance, apiURL } from 'config';
import {
  loadCategoriesSuccess,
  loadCategoriesFail,
  loadCategoriesRequested,
  loadProductsRequested,
  loadProductsSuccess,
  loadProductsFail,
} from './productSlice';

export function* loadCategoryEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.get,
      `${apiURL}/get-categories`,
      action.payload
    );
    yield put(loadCategoriesSuccess(data));
  } catch (error: any) {
    yield put(loadCategoriesFail(error.message));
  }
}

export function* loadProductEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.get,
      `${apiURL}/get-items-withsubcategory-bycategoryid?Id=${action.payload}`,
      action.payload
    );
    yield put(loadProductsSuccess(data));
  } catch (error: any) {
    yield put(loadProductsFail(error.message));
  }
}

export function* productSaga(): Generator<any, void, any> {
  yield takeEvery(loadCategoriesRequested, loadCategoryEffect);
  yield takeEvery(loadProductsRequested, loadProductEffect);
}
